import React from 'react';
import {Style} from "react-style-tag";
import Logo from './images/Blacktransparent.png'
import Umedme from './images/umedme.png'
import DE_transparant from './images/final_full_logo_grey.png'
import DE_transparant1 from './images/finalDEgrey.png'
import umedme_bw from './images/umedme_bw.png'
import Gis from './images/gis.png'
import gis_bw from './images/gis_bw.png'
import Tilli from './images/tilli.jpeg';
import Handsontree from './images/handsontree.jpg';
import coronaimage2 from './images/coronaimage_1.jpg';
import O_photo from './images/O_photo.jpg';
import O_photoCropped from './images/O_photoCropped.jpg';
import what_it_looks_like from './images/what_it_looks_like.jpg';
import innovation from './icons/innovation.png'
import Parliament from './icons/Parliament.png'
import Recruitment from './icons/Recruitment.png'
import noun_Not_Equal from './icons/noun_Not_Equal.png'
import noun_retention from './icons/noun_retention.png'
import tilli_bw from './images/tilli_bw.png';
import coronaimage_bw from './images/coronaimage2.png';
import WhoWeAre from './images/WhoWeAre.jpg';
import Bg2 from '../src/icons/mountain.png';

import de_collective from './images/collective/de_crop_new.png';

import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";


import IconImg_1 from '../src/images/EI_Icons/Icons_WEB-1.png';
import IconImg_2 from '../src/images/EI_Icons/Icons_WEB-2.png';
import IconImg_3 from '../src/images/EI_Icons/Icons_WEB-3.png';
import IconImg_4 from '../src/images/EI_Icons/Icons_WEB-4.png';
import IconImg_5 from '../src/images/EI_Icons/Icons_WEB-5.png';
import IconImg_6 from '../src/images/EI_Icons/Icons_WEB-6.png';
import IconImg_7 from '../src/images/EI_Icons/Icons_WEB-7.png';
import IconImg_8 from '../src/images/EI_Icons/Icons_WEB-8.png';
import IconImg_9 from '../src/images/EI_Icons/Icons_WEB-9.png';
import IconImg_10 from '../src/images/EI_Icons/Icons_WEB-10.png';
import IconImg_11 from '../src/images/EI_Icons/Icons_WEB-11.png';
import IconImg_12 from '../src/images/EI_Icons/Icons_WEB-12.png';
import IconImg_13 from '../src/images/EI_Icons/Icons_WEB-13.png';
import IconImg_14 from '../src/images/EI_Icons/Icons_WEB-14.png';
import IconImg_15 from '../src/images/EI_Icons/Icons_WEB-15.png';
import IconImg_16 from '../src/images/EI_Icons/Icons_WEB-16.png';
import IconImg_17 from '../src/images/EI_Icons/Icons_WEB-17.png';





class LandingPage_2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {width: 0, height: 0};
    }

    componentDidMount() {
        window.onscroll = () => {
            var header = document.getElementById("myHeader");
            if (header) {
                var sticky = header.offsetTop;
                if (window.pageYOffset > sticky) {
                    header.classList.add("sticky");
                } else {
                    header.classList.remove("sticky");
                }
            }
        };
    }

    render() {



      

          var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 6,
            initialSlide: 0,
            autoplay:true,
            autoplaySpeed:3000,
            responsive: [
              {
                breakpoint: 1920,
                settings: {
                  slidesToShow: 6,
                  slidesToScroll: 6,
                  infinite: false,
                  dots: false
                }
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4,
                  infinite: false,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 2,
                  initialSlide: 3
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
              }
            ]
          };


            let ScrollData=[]
         
                ScrollData.push( 
                  

                    
    <Slider {...settings}>
            <div className="p-1">
                 <img src={IconImg_1} className="img-responsive" />  
            </div>

            <div className="p-1">
                 <img src={IconImg_2}  className="img-responsive" />  
            </div>
            <div className="p-1">
                 <img src={IconImg_3}  className="img-responsive" />  
            </div>
            <div className="p-1">
                 <img src={IconImg_4}  className="img-responsive" />  
            </div>
            <div className="p-1">
                 <img src={IconImg_5}  className="img-responsive" />  
            </div>
            <div className="p-1">
                 <img src={IconImg_6}  className="img-responsive" />  
            </div>
            <div className="p-1">
                 <img src={IconImg_7}  className="img-responsive" />  
            </div>
            <div className="p-1">
                 <img src={IconImg_8}  className="img-responsive" />  
            </div>
            <div className="p-1">
                 <img src={IconImg_9}  className="img-responsive" />  
            </div>
            <div className="p-1">
                 <img src={IconImg_10}  className="img-responsive" />  
            </div>
            <div className="p-1">
                 <img src={IconImg_11}  className="img-responsive" />  
            </div>
            <div className="p-1">
                 <img src={IconImg_12}  className="img-responsive" />  
            </div>
            <div className="p-1">
                 <img src={IconImg_13}  className="img-responsive" />  
            </div>
            <div className="p-1">
                 <img src={IconImg_14}  className="img-responsive" />  
            </div>
            <div className="p-1">
                 <img src={IconImg_15}  className="img-responsive" />  
            </div>
            <div className="p-1">
                 <img src={IconImg_16}  className="img-responsive" />  
            </div>
            <div className="p-1">
                 <img src={IconImg_17}  className="img-responsive" />  
            </div>
    </Slider>
                        
                      )
        
              

            

        

        return (
            <React.Fragment>
                <Style>
                    {`
                    .lastRow {
                        height: 600px;
                        padding-bottom: 50px;
                        padding-top: 40px;
                    }
                    .lastRow1 {
                        padding-top: 100px;
                        padding-left:50px
                    }
                   
                .header {
                  padding: 10px;
                  color: #f1f1f1;
                  position: fixed;
                }
                .p-1 {
                    padding: 1rem;
                }
                .threeViewB {
                    paddingBottom : 30px
                }
                .threeView {
                    padding-top: 30px; padding-bottom: 30px; padding-left: 200px; padding-right: 200px
                }
                .nowrap {
                    white-space: nowrap !important;
                }
                .sticky {
                  padding: 10px;
                  top: 0;
                  z-index:1;
                //   background-image: url(${O_photoCropped});
                  background-size: cover;
                  background-repeat: no-repeat
                }
                .center {
                    position: absolute;
                    top: 45%;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                  }
                  @font-face {
                    font-family: 'Helvetica-Regular';
                    src: url('%PUBLIC_URL%/fonts/Helvetica-Font/Helvetica.otf');
                  }
                  
                  @font-face {
                    font-family: 'Helvetica-Light';
                    src: url('%PUBLIC_URL%/fonts/Helvetica-Font/helvetica-light-587ebe5a59211.ttf');
                  }
                   
                  .boldcontent{
                        text-align: center;
                        font-family: Helvetica-Regular;
                        font-size:30px;font-weight:bold;
                        white-space: nowrap !important;
                      }
                   
                .p-tag{
                    font-family:Helvetica-Light;
                       font-size:18px !important;
                        white-space: nowrap;
                        text-align: center
                 }
                 .div_bg_img {
                    padding: 10px;
                    top: 0;
                    z-index:1;
                    background-color:#d4d4d4;
                    box-shadow:0px 5px 4px #888788;	
                  }
                
                .zoom {
                    transition: transform .2s;
                    margin: 0 auto;
                    text-align: center;
                  }
                  
                  .zoom:hover {
                    -ms-transform: scale(1.2); /* IE 9 */
                    -webkit-transform: scale(1.2); /* Safari 3-8 */
                    transform: scale(1.2);
                  }                    
                .div_height{
                height: 150px;
                    }
                   .image_1{
                    background: url(${coronaimage_bw});
                   width:100%;
                   height:100%;
                   background-repeat:no-repeat;
                   background-size:contain ;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    /*manage top*/
                    margin-top:45px
}
                   }  
                   
                   .image_1:hover{
                    background-image: url(${coronaimage2});
                    width:100%;
                    filter: initial;
                    
                   }
                   .image_2{
                    background: url(${umedme_bw});
                    width:100%;height:100%;background-repeat:no-repeat;background-size:contain ;
                     display: block;
                     margin-left: auto;
                     margin-right: auto;
                   }
                   
                   .image_2:hover{
                    background-image: url(${Umedme});
                    width:100%;
                    filter: initial;
                    
                    
                   }
                   .image_3{
                    background: url(${de_collective});
                    width:100%;height:100%;background-repeat:no-repeat;background-size:contain ;
                    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                     filter: grayscale(100%);
                     
                     display: block;
                     margin-left: auto;
                     margin-right: auto;
                   }
                   .image_3:hover{
                    background-image: url(${de_collective});
                    width:100%;filter: initial;
                   }
                   .image_4{
                    background: url(${gis_bw});
                    width:100%;height:100%;background-repeat:no-repeat;background-size:contain ;
                     display: block;
                     margin-left: auto;
                     margin-right: auto;
                     margin-top: 25px;
                   }
                   .image_4:hover{
                    background-image: url(${Gis});
                    width:100%;filter: initial;
                   }
                   
                   .image_5{
                    background: url(${tilli_bw});
                    width:100%;height:100%;background-repeat:no-repeat;background-size:contain ;
                     display: block;
                     margin-left: auto;
                     margin-right: auto;
                   }
                   .image_5:hover{
                    background-image: url(${Tilli});
                    width:100%;filter: initial;
                   }
                   
                  @media screen and (min-width:900px) {
                    .p-tag{
                        font-family:Helvetica-Light;
                       font-size:18px !important;
                        white-space: nowrap;
                        text-align: center
                       }
                       
                       .lastRow {
                        height: 600px;
                    }
                    
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                  
                      
                  }
                
                @media screen and (max-width:1000px) {
               
                       .threeViewB {
                        padding-bottom : 40px
                    }
                    
                    .lastRow {
                        height: 700px;
                    }
                    
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                    
                  }
                  
                   
                  @media screen and (max-width:600px) {
                  .lastRow {
                        height: 500px;
                    }
                    
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                    
                  .threeView {
                    padding-top: 30px; padding-bottom: 30px; padding-left: 100px; padding-right: 100px
                    }
                    .threeViewB {
                        padding-bottom : 40px
                    }
                     
                    .logoImage{
                        width:300px !important;
                    }
                    .founder{
                        font-size:16px !important
                        white-space: nowrap;
                    }
                    
                      .believecontent{
                          margin-top:70px;
                          white-space: nowrap;
                         
                      }
                      
                      .p-tag{
                        font-family:Helvetica-Light;
                       font-size:18px !important;
                        white-space: nowrap;
                        text-align: center
                      }
                    .res-rem-m_b{
                        margin-bottom:0px !important;
                    }
                    .img_full_view{
                        background-repeat: round !important;
                    }
                    .div_bg_img{
                    }
                    .mbtm{
                        margin-bottom:25%;
                    }
                   
                    .rm-mtop{
                        margin-top:40% !important;
                    }
                    .custom-p-tag {
                        font-size:1rem !important;
                    }
                    
                }
                
                @media screen and (min-width: 600px) and (max-width:780px) {
           
                    
                    .lastRow {
                        height: 500px;
                    }
                    
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                    
                  }
               `}
                </Style>
                <div>
                    <div className="img_full_view" style={{
                        minHeight: 800,
                        // backgroundImage: `url(${O_photo})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}>
                        <div className="header img_full_view" id="myHeader" style={{width: '100%',backgroundColor:"#FFF"}}>
                            <div className="col-12" style={{minHeight: 120, width: '100%', padding: 20}}>
                                <img className="logoImage" src={Logo} style={{width: 50 * 7}}/>
                            </div>
                        </div>
                        <div style={{color: 'white'}}>
                            {/* <div className="center" style={{textAlign: 'center', top: '50%'}}>
                                <p className="founder" style={{
                                    fontFamily: 'Helvetica-Regular',
                                    fontWeight: 'bold',
                                    fontSize: 30,
                                    color: '#000000'
                                }}>
                                    We are a co-founder collective
                                </p>
                                <p className="founder" style={{
                                    fontFamily: 'Helvetica-Regular',
                                    fontWeight: 'bold',
                                    fontSize: 30,
                                    color: '#000000'
                                }}>
                                    Re-imagining ideation, formation, and beyond
                                </p>

                            </div> */}

                                <div className="row">

                                        <div className="col-sm-12 col-xs-12 text-center center"> 
                                        <p style={{
                                            fontFamily: 'Helvetica-Regular',
                                            fontWeight: 'bold',
                                            fontSize: 30,
                                            color: '#000000' }}>
                                           Because the world moves forward
                                            </p>

                                            <p style={{
                                            fontFamily: 'Helvetica-Regular',
                                            fontWeight: 'bold',
                                            fontSize: 30,
                                            color: '#000000' }}>
                                            when we create space for the people and ideas left behind
                                            </p>
                                        </div>


                                </div> 
                        </div>
                    </div>
                    <div className={'row'} style={{paddingRight: 0, paddingLeft: 0, marginRight: 0, marginLeft: 0}}>
                        <div className="threeView">
                            <div className="col-md-4 col-sm-6">
                                <div className="row" style={{height: 350, textAlign: 'center'}}>
                                    <img style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        maxWidth: 200,
                                        minWidth: 200
                                    }} src={WhoWeAre}/>
                                </div>
                                <div className="row" style={{paddingTop: 20}}>
                                    <div className="col-sm-12">
                                        <p>
                                            <p className={'nowrap'} style={{
                                                textAlign: 'center',
                                                paddingBottom: 10,
                                                color: 'black',
                                                fontFamily: 'Helvetica-Bold',
                                                fontSize: 24
                                            }}>
                                                Who we are
                                            </p>
                                            <p className="p-tag">We are inclusive leaders,</p>
                                            <p className="p-tag">building an equitable future</p>
                                            <p className="p-tag">with a diversity of</p>
                                            <p className="p-tag">perspectives using</p>
                                            <p className="p-tag">sustainable technology</p>
                                            <p className={'threeViewB'}></p>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="row" style={{height: 350, textAlign: 'center'}}>
                                    <img style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        maxWidth: 200,
                                        minWidth: 200
                                    }} src={Handsontree}/>
                                </div>
                                <div className="row" style={{paddingTop: 20}}>
                                    <div className="col-sm-12">
                                        <p>
                                            <p className={'nowrap'} style={{
                                                textAlign: 'center',
                                                paddingBottom: 10,
                                                color: 'black',
                                                fontFamily: 'Helvetica-Bold',
                                                fontSize: 24
                                            }}>
                                                What we believe
                                            </p>
                                            <p className="p-tag">A new model</p>
                                            <p className="p-tag">focused on</p>
                                            <p className="p-tag">the daily practice of</p>
                                            <p className="p-tag">inclusion, equity,</p>
                                            <p className="p-tag">diversity, sustainability</p>
                                            <p className={'threeViewB'}></p>

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="row" style={{height: 350, textAlign: 'center'}}>
                                    <img style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        maxWidth: 200,
                                        minWidth: 200
                                    }} src={what_it_looks_like}/>
                                </div>
                                <div className="row" style={{paddingTop: 20}}>
                                    <div className="col-sm-12">
                                        <p>
                                            <p className={'nowrap'} style={{
                                                textAlign: 'center',
                                                paddingBottom: 10,
                                                color: 'black',
                                                fontFamily: 'Helvetica-Bold',
                                                fontSize: 24
                                            }}>
                                                What it looks like
                                            </p>
                                            <p className="p-tag">Inclusive design, </p>
                                            <p className="p-tag">Equitable ownership, </p>
                                            <p className="p-tag">Diverse skill sets, </p>
                                            <p className="p-tag">Responsible innovation </p>

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{paddingTop: 30}}></div>
                    <div className={'row'} style={{paddingRight: 0, paddingLeft: 0, marginRight: 0, marginLeft: 0}}>
                        <div className="col-12" style={{width: '100%',}}>
                            <div className={'row'}
                                 style={{paddingRight: 0, paddingLeft: 0, marginRight: 0, marginLeft: 0}}>

                                <div className="col-12 " style={{textAlign: 'center', paddingBottom: 20}}>
                                    <p style={{color: 'black', fontFamily: 'Helvetica-Regular', fontSize: 30}}>
                                        {/* From the Co-Founder Collective */}
                                    </p>
                                </div>
                            </div>
                            <div className={'row'}
                           
                                 style={{paddingRight: 0, paddingLeft: 0, marginRight: 0, marginLeft: 0}}>
 {/* dummy height added  */}
                                <div className="col-12 div_bg_img" style={{paddingBottom: '0%'}}>
                                 

                                    <div className="row">

                                    <div className="col-sm-1"/>

                                    <div className="col-sm-10">


                                            {ScrollData}
                                    

                                    </div>


                                    <div className="col-sm-1"></div>
                                    </div>  

     
                                    {/* <div className="row form-group"
                                         style={{paddingTop: 20, paddingBottom: 30, marginRight: 0, marginLeft: 0}}>
                                        <div className="col-sm-1"/>
                                        <div className="col-xs-6 col-sm-2 zoom div_height">
                                            <a href="https://novelcoronanews.org/" target="_blank"
                                               title="novelcoronanews">
                                                <div className="image_1" style={{}}></div>
                                            </a>
                                        </div>
                                        <div className="col-xs-6 col-sm-2  zoom div_height">
                                            <a href="https://dataemergence.com" target="_blank" title="DataEmergence">
                                                <div className="image_3" style={{}}></div>
                                            </a>
                                        </div>
                                        <div className="col-xs-6 col-sm-2  zoom div_height">
                                            <a href="https://teqbahn.com/umedme/home" target="_blank" title="umedme">
                                                <div className="image_2" style={{}}></div>
                                            </a>
                                        </div>
                                        <div className="col-xs-6 col-sm-2  zoom div_height">
                                            <a href="https://www.globalinclusionstrategies.com/" target="_blank" title="Global Inclusion Strategies">
                                                <div className="image_4" style={{}}></div>
                                            </a>
                                        </div>
                                        <div className="col-xs-6 col-sm-2  zoom div_height">

                                            <a href="https://www.tillikids.org/" target="_blank" title="Tilli">
                                                <div className="image_5" style={{}}></div>
                                            </a>
                                        </div>
                                        <div className="col-xs-3 "></div>
                                        <div className="col-sm-1"/>

                                    </div> */}

                                </div>
                            </div>

                            <div className="row res-rem-m_b" style={{padding: 0, margin: 0, marginBottom: 0}}>
                                <div className="col-sm-6 lastRow">
                                    <img style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                        paddingLeft: 50,
                                        paddingRight: 50,
                                    }} src={Bg2}/>
                                </div>
                                <div className="col-sm-6 lastRow" style={{ padding: 0, margin: 0}}>
                                    <div className='center lastRow1' style={{width: '100%'}}>
                                        <p style={{
                                            paddingBottom: 20,
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Bold',
                                            fontSize: 24
                                        }}>
                                            What we do
                                        </p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span style={{}}> <img src={innovation} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }}/></span>Responsible innovation (T)</p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span> <img src={noun_Not_Equal} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }}/></span>Reduce structural inequalities (eq)</p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span> <img src={noun_retention} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }}/></span>Increase access to optimal resources (bahn)</p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span> <img src={Recruitment} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }}/></span>Recruit based on values</p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span> <img src={Parliament} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }}/></span>Good governance</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </React.Fragment>
        );
    }
}

export default LandingPage_2;