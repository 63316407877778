import React from 'react';
import { Style } from "react-style-tag";
import Logo from './images/Blacktransparent.png'
import Umedme from './images/umedme.png'
import DE_transparant from './images/final_full_logo_grey.png'
import DE_transparant1 from './images/finalDEgrey.png'
import umedme_bw from './images/umedme_bw.png'
import Gis from './images/gis.png'
import gis_bw from './images/gis_bw.png'
import Tilli from './images/tilli.jpeg';
import Handsontree from './images/handsontree.jpg';
import coronaimage2 from './images/coronaimage_1.jpg';
import O_photo from './images/O_photo.jpg';
import satty_singh from './images/satty-singh.jpg';
import O_photoCropped from './images/O_photoCropped.jpg';
import what_it_looks_like from './images/what_it_looks_like.jpg';
import innovation from './icons/innovation.png'
import Parliament from './icons/Parliament.png'
import Recruitment from './icons/Recruitment.png'
import noun_Not_Equal from './icons/noun_Not_Equal.png'
import noun_retention from './icons/noun_retention.png'
import tilli_bw from './images/tilli_bw.png';
import coronaimage_bw from './images/coronaimage2.png';
import WhoWeAre from './images/WhoWeAre.jpg';
import Bg2 from '../src/icons/mountain.png';

import de_collective from './images/collective/de_crop_new.png';

import e_web_03 from './images/e-web/Goal-03.png';
import e_web_04 from './images/e-web/Goal-04.png';
import e_web_05 from './images/e-web/Goal-05.png';
import e_web_08 from './images/e-web/Goal-08.png';
import e_web_09 from './images/e-web/Goal-09.png';
import e_web_10 from './images/e-web/Goal-10.png';
import e_web_17 from './images/e-web/Goal-17.png';

import Config from "./helper/Config";
import { Link } from "react-router-dom";




class LandingPage_3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
    }

    componentDidMount() {
        window.onscroll = () => {
            var header = document.getElementById("myHeader");
            if (header) {
                var sticky = header.offsetTop;
                if (window.pageYOffset > sticky) {
                    header.classList.add("sticky-header");
                } else {
                    header.classList.remove("sticky-header");
                }
            }
        };
    }

    render() {
        
        return (
            <React.Fragment>
                <Style>
                    {`
                    .lastRow {
                        height: 600px;
                        padding-bottom: 50px;
                        padding-top: 40px;
                    }
                    .lastRow1 {
                        padding-top: 100px;
                        padding-left:50px
                    }
                   
                .header {
                  padding: 10px;
                  color: #f1f1f1;
                  position: fixed;
                }
                .p-1 {
                    padding: 1rem;
                }
                .threeViewB {
                    paddingBottom : 30px
                }
                .threeView {
                    padding-top: 30px; padding-bottom: 30px; padding-left: 200px; padding-right: 200px
                }
                .nowrap {
                    white-space: nowrap !important;
                }
                .sticky {
                  padding: 10px;
                  top: 0;
                  z-index:1;
                //   background-image: url(${O_photoCropped});
                  background-size: cover;
                  background-repeat: no-repeat
                  background-color: #fff;
                }

                 .header-bg{
                    background-image: url(${O_photoCropped});
                    background-size: cover;
                    width: 100%;
                    background-repeat: no-repeat;
                 }
                .center {
                    position: absolute;
                    top: 45%;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                  }
                  @font-face {
                    font-family: 'Helvetica-Regular';
                    src: url('%PUBLIC_URL%/fonts/Helvetica-Font/Helvetica.otf');
                  }
                  
                  @font-face {
                    font-family: 'Helvetica-Light';
                    src: url('%PUBLIC_URL%/fonts/Helvetica-Font/helvetica-light-587ebe5a59211.ttf');
                  }
                   
                  .boldcontent{
                        text-align: center;
                        font-family: Helvetica-Regular;
                        font-size:30px;font-weight:bold;
                        white-space: nowrap !important;
                      }
                   
                .p-tag{
                    font-family:Helvetica-Light;
                       font-size:18px !important;
                        white-space: nowrap;
                        text-align: center
                 }
                 .div_bg_img {
                    padding: 10px;
                    top: 0;
                    z-index:1;
                    background-color:#d4d4d4;
                    box-shadow:0px 5px 4px #888788;	
                  }
                
                .zoom {
                    transition: transform .2s;
                    margin: 0 auto;
                    text-align: center;
                  }
                  
                  .zoom:hover {
                    -ms-transform: scale(1.2); /* IE 9 */
                    -webkit-transform: scale(1.2); /* Safari 3-8 */
                    transform: scale(1.2);
                  }                    
                .div_height{
                height: 150px;
                    }
                   .image_1{
                    background: url(${coronaimage_bw});
                   width:100%;
                   height:100%;
                   background-repeat:no-repeat;
                   background-size:contain ;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    /*manage top*/
                    margin-top:45px
}
                   }  
                   
                   .image_1:hover{
                    background-image: url(${coronaimage2});
                    width:100%;
                    filter: initial;
                    
                   }
                   .image_2{
                    background: url(${umedme_bw});
                    width:100%;height:100%;background-repeat:no-repeat;background-size:contain ;
                     display: block;
                     margin-left: auto;
                     margin-right: auto;
                   }
                   
                   .image_2:hover{
                    background-image: url(${Umedme});
                    width:100%;
                    filter: initial;
                    
                    
                   }
                   .image_3{
                    background: url(${de_collective});
                    width:100%;height:100%;background-repeat:no-repeat;background-size:contain ;
                    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                     filter: grayscale(100%);
                     
                     display: block;
                     margin-left: auto;
                     margin-right: auto;
                   }
                   .image_3:hover{
                    background-image: url(${de_collective});
                    width:100%;filter: initial;
                   }
                   .image_4{
                    background: url(${gis_bw});
                    width:100%;height:100%;background-repeat:no-repeat;background-size:contain ;
                     display: block;
                     margin-left: auto;
                     margin-right: auto;
                     margin-top: 25px;
                   }
                   .image_4:hover{
                    background-image: url(${Gis});
                    width:100%;filter: initial;
                   }
                   
                   .image_5{
                    background: url(${tilli_bw});
                    width:100%;height:100%;background-repeat:no-repeat;background-size:contain ;
                     display: block;
                     margin-left: auto;
                     margin-right: auto;
                   }
                   .image_5:hover{
                    background-image: url(${Tilli});
                    width:100%;filter: initial;
                   }
                   
                  @media screen and (min-width:900px) {
                    .p-tag{
                        font-family:Helvetica-Light;
                       font-size:18px !important;
                        white-space: nowrap;
                        text-align: center
                       }
                       
                       .lastRow {
                        height: 600px;
                    }
                    
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                  
                      
                  }
                
                @media screen and (max-width:1000px) {
               
                       .threeViewB {
                        padding-bottom : 40px
                    }
                    
                    .lastRow {
                        height: 700px;
                    }
                    
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                    
                  }
                  
                   
                  @media screen and (max-width:600px) {
                  .lastRow {
                        height: 500px;
                    }
                    
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                    
                  .threeView {
                    padding-top: 30px; padding-bottom: 30px; padding-left: 100px; padding-right: 100px
                    }
                    .threeViewB {
                        padding-bottom : 40px
                    }
                     
                    .logoImage{
                        width:300px !important;
                    }
                    .founder{
                        font-size:16px !important
                        white-space: nowrap;
                    }
                    
                      .believecontent{
                          margin-top:70px;
                          white-space: nowrap;
                         
                      }
                      
                      .p-tag{
                        font-family:Helvetica-Light;
                       font-size:18px !important;
                        white-space: nowrap;
                        text-align: center
                      }
                    .res-rem-m_b{
                        margin-bottom:0px !important;
                    }
                    .img_full_view{
                        background-repeat: round !important;
                    }
                    .div_bg_img{
                    }
                    .mbtm{
                        margin-bottom:25%;
                    }
                   
                    .rm-mtop{
                        margin-top:40% !important;
                    }
                    .custom-p-tag {
                        font-size:1rem !important;
                    }
                    
                }
                
                .e-web {
                    margin: 10px 0;
                }
                @media screen and (min-width: 600px) and (max-width:780px) {
           
                    
                    .lastRow {
                        height: 500px;
                    }
                    
                    .lastRow1 {
                        padding-top: 50px;
                        padding-left:50px
                    }
                    
                  }

                  @media (max-width: 480px){
                    .seven-cols .col-md-1,
                    .seven-cols .col-sm-1,
                    .seven-cols .col-lg-1  {
                      width: 50%;
                      float:left
                    }
                    .col-offset-1 {
                        margin-left: 25%;
                    }
                  }
                  
                  @media (min-width: 481px){
                    .seven-cols .col-md-1,
                    .seven-cols .col-sm-1,
                    .seven-cols .col-lg-1  {
                      width: 33.33%;
                      float: left;
                    }

                    .col-offset-1 {
                        margin-left: 33.333333%;
                    }
                  }
                  
                  @media (min-width: 767px) {
                    .seven-cols .col-md-1,
                    .seven-cols .col-sm-1,
                    .seven-cols .col-lg-1 {
                      width: 14.285714285714285714285714285714%;
                      float: left;
                    }
                    .col-offset-1 {
                        margin-left: 0;
                    }
                  }
               `}
                </Style>
                <div>
                    <div className="header-with-menu">
                        <div className="logo padding" id="myHeader">
                            <img src={Logo}/>
                        </div>
                        <div className="bg">
                            <img src={satty_singh} className="header-bg-image"/>
                            <div className="menu" id="fixed-menu">
                                <nav>
                                    <button class="navbar-toggle" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span class="sr-only">Toggle navigation</span>
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                    </button>

                                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul class="nav navbar-nav navbar-right">
                                            <li className="nav-item px-2" >
                                                <Link to={Config.projectUrl + "/"} className="nav-link-black">HOME</Link>
                                            </li>
                                            <li className="nav-item px-2" >
                                                <a href="http://teqbahn.org/TeqED" className="nav-link-about">TeqED</a>
                                            </li>
                                            <li className="nav-item px-2" >
                                                <Link to={Config.projectUrl + "/about"} className="nav-link-black">ABOUT</Link>
                                            </li>
                                            <li className="nav-item px-2" >
                                                <a href="http://teqbahn.com/" target="_blank" className="nav-link-black">COM</a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                            <div className="row bg-text bg-text-mobile" style={{paddingRight: 0, paddingLeft: 0, marginRight: 0, marginLeft: 0}}>
                                <div className="col-sm-12 col-xs-12 text-center">
                                    <p className="header-main-text">
                                        Because the world moves forward
                                    </p>
                                    <p className="header-main-text">
                                    when we create space for the people and ideas left behind
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'row div_bg_img'} style={{ paddingRight: 0, paddingLeft: 0, margin: "20px 0" }}>
                        <div className="col-12">
                            <div className="container seven-cols">
                                <div className="col-sm-1">
                                    <a href="https://sdgs.un.org/goals/goal3" target="_blank">
                                        <img src={e_web_03} className="img-responsive e-web" />
                                    </a>
                                </div>
                                <div className="col-sm-1">
                                    <a href="https://sdgs.un.org/goals/goal4" target="_blank">
                                        <img src={e_web_04} className="img-responsive e-web" />
                                    </a>
                                </div>
                                <div className="col-sm-1">
                                    <a href="https://sdgs.un.org/goals/goal5" target="_blank">
                                        <img src={e_web_05} className="img-responsive e-web" />
                                    </a>
                                </div>
                                <div className="col-sm-1">
                                    <a href="https://sdgs.un.org/goals/goal8" target="_blank">
                                        <img src={e_web_08} className="img-responsive e-web" />
                                    </a>
                                </div>
                                <div className="col-sm-1">
                                    <a href="https://sdgs.un.org/goals/goal9" target="_blank">
                                        <img src={e_web_09} className="img-responsive e-web" />
                                    </a>
                                </div>
                                <div className="col-sm-1">
                                    <a href="https://sdgs.un.org/goals/goal10" target="_blank">
                                        <img src={e_web_10} className="img-responsive e-web" />
                                    </a>
                                </div>
                                <div className="col-offset-1 col-sm-1">
                                    <a href="https://sdgs.un.org/goals/goal17" target="_blank">
                                        <img src={e_web_17} className="img-responsive e-web" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'row'} style={{ paddingRight: 0, paddingLeft: 0, marginRight: 0, marginLeft: 0 }}>
                        <div className="col-12">
                            <div className="row res-rem-m_b" style={{ padding: 0, margin: 0, marginBottom: 0 }}>
                                <div className="col-sm-6 lastRow">
                                    <img style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                        paddingLeft: 50,
                                        paddingRight: 50,
                                    }} src={Bg2} />
                                </div>
                                <div className="col-sm-6 lastRow" style={{ padding: 0, margin: 0 }}>
                                    <div className='center lastRow1' style={{ width: '100%' }}>
                                        <p style={{
                                            paddingBottom: 20,
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Bold',
                                            fontSize: 24
                                        }}>
                                            What we do
                                        </p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span style={{}}> <img src={innovation} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }} /></span>Responsible innovation (T)</p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span> <img src={noun_Not_Equal} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }} /></span>Reduce structural inequalities (eq)</p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span> <img src={noun_retention} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }} /></span>Increase access to optimal resources (bahn)</p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span> <img src={Recruitment} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }} /></span>Recruit based on values</p>
                                        <p className="p-tag pleft-1 custom-p-tag" style={{
                                            paddingBottom: "2%",
                                            textAlign: 'left',
                                            fontFamily: 'Helvetica-Light'
                                        }}><span> <img src={Parliament} style={{
                                            width: 65,
                                            height: 65,
                                            backgroundColor: "#D3D3D3",
                                            borderRadius: 65 / 2,
                                            border: '2px solid black',
                                            marginRight: 20
                                        }} /></span>Good governance</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </React.Fragment>
        );
    }
}

export default LandingPage_3;