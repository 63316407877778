import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import LandingPage from '../src/LandingPage'
import Footer from '../src/Footer'
import Header from '../src/Header'
import LandingPage_2 from '../src/LandingPage_2'
import LandingPage_3 from '../src/LandingPage_3'
import About from './About'
import About2 from './About2'
import Footer_2 from '../src/Footer_2'
import Config from '../src/helper/Config';
import createHistory from 'history/createBrowserHistory';
import TeqEd from './TeqEd';
import PrivacyPolicy from './PrivacyPolicy';




const history = createHistory();
const projectUrl = Config.projectUrl;

class App extends React.Component {


  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }


  render() {

    return (
      <div>

        <Router>

          <Route exact path={projectUrl + "/test2"} history={history} render={(props) =>
            <React.Fragment>
              <LandingPage {...props} />
              <Footer />
            </React.Fragment>
          } />
          <Route exact path={projectUrl + "/test"} history={history} render={(props) =>
            <React.Fragment>
              <LandingPage_2 {...props} />
              <Footer_2 />
            </React.Fragment>
          } />
          <Route exact path={projectUrl + "/"} history={history} render={(props) =>
            <React.Fragment>
              <LandingPage_3 {...props} />
              <Footer />
            </React.Fragment>
          } />
          <Route exact path={projectUrl + "/TeqED"} history={history} render={(props) =>
            <React.Fragment>
              <TeqEd {...props} />
              <Footer />
            </React.Fragment>
          } />
          <Route exact path={projectUrl + "/about"} history={history} render={(props) =>
            <React.Fragment>
              <About {...props} />
              <Footer />
            </React.Fragment>
          } />
          <Route exact path={projectUrl + "/about2"} history={history} render={(props) =>
            <React.Fragment>
              <About2 {...props} />
              <Footer_2 />
            </React.Fragment>
          } />
          <Route exact path={projectUrl + "/privacy-policy"} history={history} render={(props) =>
            <React.Fragment>
              <PrivacyPolicy {...props} />
              <Footer />
            </React.Fragment>
          } />
        </Router>
      </div>
    )

  }

}

export default App;
