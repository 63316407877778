import React from 'react';
import about1 from './images/about/1.jpg';
import evis_e from './images/about/evis-e.jpg';
import benjamin_elliott from './images/about/benjamin-elliott.jpg';
import lauza_loistl from './images/about/lauza-loistl.jpg';
import emma_gosset from './images/about/emma-gosset.jpg';
import Logo from './images/Blacktransparent.png'
import { Link } from "react-router-dom";
import Config from "./helper/Config";
import christina_woc from './images/about/christina-woc.jpg';


export default class TeqEd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setZIndex: 1
        }
    }

    componentDidMount() {
        window.onscroll = () => {
            var header = document.getElementById("myHeader");
            if (header) {
                var sticky = header.offsetTop;
                if (window.pageYOffset > sticky) {
                    header.classList.add("about-sticky-crop");
                } else {
                    header.classList.remove("about-sticky-crop");
                }
            }
        };
        this.imageHeightSet();
        let _this = this;
        window.addEventListener('resize', function (event) {
            _this.imageHeightSet();
        });
    }

    imageHeightSet() {
        let screenWidth = window.innerWidth;

        let div = document.querySelectorAll(".about-div-content");
        for (var i = 0, len = div.length; i < len; i++) {
            if (screenWidth < 768) {
                let height = window.getComputedStyle(div[i].querySelector(".content-mobile")).height;
                div[i].querySelector(".about-content-image").style.height = height;
            } else {
                div[i].querySelector(".about-content-image").style.height = 'auto';
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="header-with-menu">
                    <div className="logo padding" id="myHeader">
                        <img src={Logo} />
                    </div>
                    <div className="bg">
                        <img src={about1} className="header-bg-image" />
                        <div className="menu" id="fixed-menu">
                            <nav>
                                <button class="navbar-toggle" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="sr-only">Toggle navigation</span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                </button>

                                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul class="nav navbar-nav navbar-right">
                                        <li className="nav-item px-2" >
                                            <Link to={Config.projectUrl + "/"} className="nav-link-about">HOME</Link>
                                        </li>
                                        <li className="nav-item px-2 active" >
                                            <Link to={Config.projectUrl + "/TeqED"} className="nav-link-about">TEQED</Link>
                                        </li>
                                        <li className="nav-item px-2" >
                                            <Link to={Config.projectUrl + "/about"} className="nav-link-about">ABOUT</Link>
                                        </li>
                                        <li className="nav-item px-2" >
                                            <a href="http://teqbahn.com/" target="_blank" className="nav-link-about">COM</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
                <main className="about">
                    
                </main>
            </React.Fragment>
        )
    }
}

