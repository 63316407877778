import React from 'react';
import Config from "./helper/Config";
import { Link } from 'react-router-dom';
import footer1 from './images/footer-1.png';
import { Style } from "react-style-tag";

const projectUrl = Config.projectUrl;

class Footer_2 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      privacy: 0
    }
  }

  async componentDidMount() {
    let check = await localStorage.getItem("accept");
    if (!check) {
      this.setState({ privacy: 1 })
    }
  }

  render() {
    return (
      <React.Fragment>


        <Style>
          {`

.p-tag-font{
    font-family:Helvetica-Regular;
    font-size:16px; 
    text-align: center;
    color:white



  }
.p-tag-font1{
    font-family:Helvetica-Regular;
    font-size:18px; 
    text-align: center;
    color:white



  }
  .span-tag{
    font-family:Helvetica-Regular;
    font-size:18px; 
    text-align: center;
    color:white
  }
  .footerTextcontent
  {
    margin-top: 5px;  
    text-align: center;
    line-height: 1.79em;
  }
  .blackbar {
        min-height: 130px
  }
  @media screen and (max-width:600px) {
    .blackbar {
      min-height: 135px
      }
    .p-tag-font{
        font-family:Helvetica-Regular;
    font-size:16px; 
    text-align: center;
    color:white
     }
     .p-tag-font1{
    font-family:Helvetica-Regular;
    font-size:18px; 
    text-align: center;
    color:white



  }
     .span-tag
      {
        font-size:18px !important; 
     }
     .footerTextcontent
        {
            margin-top: 5px;  
        }

  }


  @media screen and (min-width:600px && max-width:900px) {
      .blackbar {
        min-height: 135px
      }
    .footerTextcontent{
        margin-top: 5px;  
    }
  }
  .come{
    width: 100%;
    height: 100px;
    position:fixed;
    animation:comeMove 5s;
    bottom:-2px;
  }
  @keyframes comeMove {
    from{bottom:-150px;opacity:0} 
    to{bottom:-2px;opacity:1}
  }

  .go{
    width: 100%;
    height: 100px;
    position:fixed;
    animation:goMove 5s;
  }
  @keyframes goMove {
    to{bottom:-150px;opacity:0} 
    from{bottom:-2px;opacity:1}
  }

  .f-text {
    color: #fff;
    font-size: 18px;
    font-weight: 100;
    font-family: Helvetica-Regular;
  }
  .f-text-end {
    color: #fff;
    font-size: 18px;
    font-weight: 100;
    font-family: Helvetica-Regular;
  }
  .f-text-end a {
    color: #fff;
  }
  `}

        </Style>

        <div className="row">
          <div className="col-12 blackbar" style={{ backgroundColor: 'black', position: 'absolute', backgroundRepeat: "no-repeat", width: '100%', backgroundSize: 'cover', marginLeft: 15, padding: '35px 10px' }} >

            <div className="row text-center py-1 f-text" style={{paddingRight: 0, paddingLeft: 0, marginRight: 0, marginLeft: 0}}>
                <div className="col-sm-12">
                    <p>Teqbahn Foundation Targets <br/>
                  SDG #3 Good Health and Well Being; SDG #4 Quality Education; SDG #5 Gender Equality; <br/>
                  SDG #8 Decent Work and Economic Growth; SDG #9 Industry, Innovation, and Infrastructure; <br/>
                  SDG #10 Reduced Inequalities; SDG #17 Partnerships for the Goals
</p>
                </div>
            </div>
            <div className="row text-center" style={{paddingRight: 0, paddingLeft: 0, marginRight: 0, marginLeft: 0}}>
                <div className="col-sm-12">
                    <div className="">
                        <img className="footer-1" src={footer1} />
                    </div>
                </div>
            </div>
            <div className="row text-center py-1 f-text" style={{paddingRight: 0, paddingLeft: 0, marginRight: 0, marginLeft: 0}}>
                <div className="col-sm-12">
                    <p>Teqbahn Foundation supports the Sustainable Development Goals</p>
                </div>
            </div>
            <div className="row text-center py-1 f-text-end" style={{paddingRight: 0, paddingLeft: 0, marginRight: 0, marginLeft: 0}}>
                <div className="col-sm-12">
                    <p>244 Fifth Avenue, Suite 2724, New York, N.Y. 10001. Email: Contact@teqbahn.com</p>
                    <p>&#169;2021 by Teqbahn | <Link to={Config.projectUrl+"/privacy-policy"}>Privacy Policy</Link></p>
                </div>
            </div>

            <div className={this.state.privacy ? 'come privacy-policy' : 'go privacy-policy'}>
              <div className='row'>
                <div className='col-sm-1' />
                <div className='col-sm-8' style={{ marginTop: 40 }}>
                  <p style={{ fontWeight: 'bold', fontSize: 22, color: '#000' }}> We have updated our <a style={{ color: '#000', textDecoration: 'underline' }} href={projectUrl + "/privacy-policy"}>Privacy Policy</a>. Do you agree to allow cookies?</p>
                </div>
                <div className='col-sm-2' style={{ marginTop: 40, paddingLeft: 30, paddingLeft: 80 }}>
                  <div className="agree-button">
                    <a href='javascript:void(0)' style={{ backgroundColor: '#FFF', textAlign: 'center', textDecoration: 'none' }} onClick={() => {
                      localStorage.setItem("accept", "1");
                      this.setState({ privacy: 0 })
                    }}>
                      <p style={{ fontSize: 18, color: '#000', padding: 5 }}> OK, I AGREE </p>
                    </a>
                  </div>
                </div>
                <div className='col-sm-1' />
              </div>
            </div>
          </div>
        </div>

      </React.Fragment>
    );
  }
}

export default Footer_2;
