import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Logo from './images/Blacktransparent.png';
import Config from "./helper/Config";

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    menuClick() {
        window.scrollTo(0, 0);
    }
    render() {
        let { page } = this.props;
        return (
            <React.Fragment>
                <div id="header" className="fixed-top">
                    <nav class="navbar navbar-expand-lg navbar-light bg-light">
                        <Link className="navbar-brand" to={Config.projectUrl + "/"} >
                                <img src={Logo} />
                        </Link>
                        <button class="navbar-toggle" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>

                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="nav navbar-nav navbar-right">
                                <li className={page === "home" ? "nav-item px-2 active" : "nav-item px-2"} >
                                    <Link to={Config.projectUrl + "/test2"} className="nav-link" onClick={() => this.menuClick()}>HOME</Link>
                                </li>
                                <li className={page === "about" ? "nav-item px-2 active" : "nav-item px-2"} >
                                    <Link to={Config.projectUrl + "/about"} className="nav-link" onClick={() => this.menuClick()}>ABOUT</Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </React.Fragment>
        )
    }
}