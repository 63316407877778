import React from 'react';
import about1 from './images/about/1.jpg';
import evis_e from './images/about/evis-e.jpg';
import benjamin_elliott from './images/about/benjamin-elliott.jpg';
import lauza_loistl from './images/about/lauza-loistl.jpg';
import emma_gosset from './images/about/emma-gosset.jpg';
import Logo from './images/Blacktransparent.png'
import { Link } from "react-router-dom";
import Config from "./helper/Config";
import christina_woc from './images/about/christina-woc.jpg';


export default class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setZIndex: 1
        }
    }

    componentDidMount() {
        window.onscroll = () => {
            var header = document.getElementById("myHeader");
            if (header) {
                var sticky = header.offsetTop;
                if (window.pageYOffset > sticky) {
                    header.classList.add("about-sticky-crop");
                } else {
                    header.classList.remove("about-sticky-crop");
                }
            }
        };
        this.imageHeightSet();
        let _this = this;
        window.addEventListener('resize', function (event) {
            _this.imageHeightSet();
        });
    }

    imageHeightSet() {
        let screenWidth = window.innerWidth;

        let div = document.querySelectorAll(".about-div-content");
        for (var i = 0, len = div.length; i < len; i++) {
            if (screenWidth < 768) {
                let height = window.getComputedStyle(div[i].querySelector(".content-mobile")).height;
                div[i].querySelector(".about-content-image").style.height = height;
            } else {
                div[i].querySelector(".about-content-image").style.height = 'auto';
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="header-with-menu">
                    <div className="logo padding" id="myHeader">
                        <img src={Logo} />
                    </div>
                    <div className="bg">
                        <img src={about1} className="header-bg-image" />
                        <div className="menu" id="fixed-menu">
                            <nav>
                                <button class="navbar-toggle" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="sr-only">Toggle navigation</span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                </button>

                                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul class="nav navbar-nav navbar-right">
                                        <li className="nav-item px-2" >
                                            <Link to={Config.projectUrl + "/"} className="nav-link-about">HOME</Link>
                                        </li>
                                        <li className="nav-item px-2" >
                                            <a href="http://teqbahn.org/TeqED" className="nav-link-about">TeqED</a>
                                        </li>
                                        <li className="nav-item px-2 active" >
                                            <Link to={Config.projectUrl + "/about"} className="nav-link-about">ABOUT</Link>
                                        </li>
                                        <li className="nav-item px-2" >
                                            <a href="http://teqbahn.com/" target="_blank" className="nav-link-about">COM</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
                <main className="about">
                    <div className="container px-0">
                        <div className="row">
                            <div className="col-sm-12">
                                <h3 className="h3-mobile">The Greater Good</h3>
                            </div>
                        </div>
                        <div className="row about-div-content">
                            <div className="col-sm-6">
                                <p className="content content-mobile">
                                    The greater good lives in every person, team, cause, idea, and company. We believe in the power of disruption for equitable progress. We are a collection of inclusive
                                    leaders committed to bringing new ideas to life. We believe	in forward momentum based on a new model of economic participation that holds the daily practice of inclusion, equity, diversity, and sustainability at its core.
                                <b>We commit to responsible innovation (T), reduction of structural inequalities (eq), and practices to increase access to resources (bahn).</b>
                                </p>
                            </div>
                            <div className="col-sm-6">
                                <img src={evis_e} className="img-responsive about-content-image about-content-image-mobile" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3 className="h3-mobile">Equitable Inclusion By Design</h3>
                            </div>
                        </div>
                        <div className="row about-div-content">
                            <div className="col-sm-6">
                                <p className="content content-mobile">
                                    Traditional models of corporate development explicitly and implicitly exclude members of society, whether by gender, race, class, or an intersection of these and other factors. This exclusion creates a ripple effect – one that not only limits economic opportunity, but which also deprives the world of solutions. Driven by a deep commitment to equity and inclusion,
                                <b>Teqbahn uses shared values for social good to transform the way we think about business.</b> By reframing traditional models, Teqbahn fosters collaboration to prioritize the reduction of structural inequities.
                                </p>
                            </div>
                            <div className="col-sm-6">
                                <img src={benjamin_elliott} className="img-responsive about-content-image about-content-image-mobile" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3 className="h3-mobile">Integrity Sets Culture</h3>
                            </div>
                        </div>
                        <div className="row about-div-content">
                            <div className="col-sm-6">
                                <p className="content content-mobile">
                                    <b>We take companies from concept to market</b> and along the way, normalize a model and culture that disrupts harmful business practices, which have been widely accepted for far too long.
                                <b>We partner based on values</b>, employ good governance, and	align key business objectives with powerful social movements.
                                </p>
                            </div>
                            <div className="col-sm-6">
                                <img src={lauza_loistl} className="img-responsive about-content-image about-content-image-mobile" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3 className="h3-mobile">Principles In Every Project</h3>
                            </div>
                        </div>
                        <div className="row about-div-content">
                            <div className="col-sm-6">
                                <p className="content content-mobile">
                                    We will solve the problems of the world only through the lens of the people of the world. We recognize that globally, everyday experts are well positioned to shift the course of events that
                                    widen the income gap, profit from under resourced and overlooked communities, and overlook human rights violations. Partnership includes branding, governance and incorporation, building technology, a thought team to guide the process, and an equitable
                                division of assets. <b>The goal is to bring ideas to market that start
                                    with a problem and the inspiration to solve it, and end with a
                                marketable product that is accessible and inclusive. </b>
                                    <br />
                                No value is  dispensable
                                <br />
                                – this is how you build a different journey for a different future.
                                </p>
                            </div>
                            <div className="col-sm-6">
                                <img src={emma_gosset} className="img-responsive about-content-image about-content-image-mobile" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3 className="h3-mobile">Opportunity For Everyone</h3>
                            </div>
                        </div>
                        <div className="row about-div-content">
                            <div className="col-sm-6">
                                <p className="content content-mobile">
                                    Through the Teqbahn Foundation, the structure of this model is extended to include women who are members of marginalized
                                    communities – groups who do not traditionally have access to knowledge or opportunities in respect to financial matters,
                                    whether it is basic financial literacy or being able to successfully acquire investment. This non-profit arm of
                                <b>Teqbahn works through community networks to build economic ecosystems to bring solutions to life, driven by Everyday Experts</b> and the will to overcome systemic barriers limiting the participation of women in economic markets.
                                </p>
                            </div>
                            <div className="col-sm-6">
                                <img src={christina_woc} className="img-responsive about-content-image about-content-image-mobile" />
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}

